<template>
  <svg viewBox="0 0 18 19">
    <path
      d="M17.0025 0.5H0.9975C0.733553 0.501963 0.480974 0.607686 0.29433 0.79433C0.107686 0.980974 0.00196251 1.23355 0 1.4975V17.5025C0.00196251 17.7664 0.107686 18.019 0.29433 18.2057C0.480974 18.3923 0.733553 18.498 0.9975 18.5H9.615V11.54H7.275V8.8175H9.615V6.815C9.615 4.49 11.0325 3.2225 13.1175 3.2225C13.815 3.2225 14.5125 3.2225 15.21 3.3275V5.75H13.7775C12.645 5.75 12.4275 6.29 12.4275 7.0775V8.81H15.1275L14.775 11.5325H12.4275V18.5H17.0025C17.2664 18.498 17.519 18.3923 17.7057 18.2057C17.8923 18.019 17.998 17.7664 18 17.5025V1.4975C17.998 1.23355 17.8923 0.980974 17.7057 0.79433C17.519 0.607686 17.2664 0.501963 17.0025 0.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "FacebookIcon",
};
</script>
